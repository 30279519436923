import {createSlice, PayloadAction} from "@reduxjs/toolkit";

interface loggedUser {
    username: string;
    token: string;
}

const initialState: loggedUser = {
    username: '',
    token: '',
};


const explodedDrawingSlice = createSlice({
    name: "loggedUser",
    initialState,
    reducers: {
        setLoggedUser(state, action: PayloadAction<loggedUser>) {
            state.token = action.payload.token ?? ""
            state.username = action.payload.username ?? ""
        }
    },
});

export const {
    setLoggedUser,
} = explodedDrawingSlice.actions;

export default explodedDrawingSlice.reducer;
