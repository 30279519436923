import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";


// export let apiPath : string;
// if (process.env.BASE_API_PATH) {
//   apiPath = process.env.BASE_API_PATH;
// } else {
//   apiPath = "https://sabart-esplosi.metislab.net/api/carraro/";
// }
//TODO: pezza momentanea
export let apiPath = 'https://esplosi-mtd.sabart.it/api/carraro/';
export const api = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    baseUrl: apiPath,
    prepareHeaders: (headers, api) => {
      const storageToken = localStorage.getItem("token");
      // @ts-ignore
      const storeToken = api.getState().auth.jwt as string
      // console.log(api)
      // @ts-ignore
      if (api.getState().auth.loggedIn && ( !storageToken || !storeToken || storageToken !== storeToken)) {
        window.location.reload()
      }

      headers.set("Authorization", `Bearer ${storageToken}`);
      return headers;
    },
  }),
  tagTypes: [
    "Auth",
    "ExplodedDrawings",
  ],
  endpoints: () => ({}),
});
